<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(
  defineProps<{
      withHeader?: boolean;
      backdrop?: boolean;
      clickOutside?: boolean;
      dimensions?: {
        width?: string;
        height?: string;
      };
      lockScroll?: boolean;
      title?: string;
      dismissable?: boolean;
      preset?: 'dialog' | 'embed' | 'image' | 'video' | 'pacsbin' | 'iframe' | 'text' | 'form';
    }>(),
  {
    withHeader: false,
    backdrop: true,
    clickOutside: true,
    dimensions: undefined,
    lockScroll: true,
    title: undefined,
    preset: undefined,
    dismissable: true,
  },
);

const {
  withHeader,
  backdrop,
  clickOutside,
  lockScroll,
  title,
  preset,
} = toRefs(props);

const windowStyles = computed(() => {
  if (props.dimensions && props.dimensions.width && props.dimensions.height) {
    return {
      width: props.dimensions.width,
      height: props.dimensions.height,
    };
  }

  return {};
});

const emit = defineEmits<{
  (e: 'dismiss'): void;
}>();

const dismiss = () => {
  emit('dismiss');
};

const handleEscape = (e: any) => {
  if (e.key === 'Escape') {
    dismiss();
  }
};

const handleClickOutside = (e: any) => {
  if (e.target === e.currentTarget && clickOutside.value && props.dismissable) {
    dismiss();
  }
};

onMounted(() => {
  if (lockScroll.value) {
    document.body.style.overflow = 'hidden';
  }
  window.addEventListener('keydown', handleEscape);
});

onUnmounted(() => {
  if (lockScroll.value) {
    document.body.style.overflow = 'auto';
  }
  window.removeEventListener('keydown', handleEscape);
});
</script>

<template>
  <Teleport to="body">
    <div
      v-bind="$attrs"
      :class="{
        'm-modal': true,
        'with-backdrop': backdrop,
        'with-header': withHeader,
        [`preset-${preset}`]: true
      }"
      @click="handleClickOutside"
    >
      <header
        v-if="!withHeader"
        class="no-header"
      >
        <MFlexSpacer />
        <section class="actions">
          <slot name="actions" />
          <a
            v-if="dismissable"
            href="#dismiss-modal"
            class="close"
            @click.prevent="dismiss"
          >
            <MIcon icon="xmark" />
          </a>
        </section>
      </header>
      <div class="window">
        <!-- In window header and controls -->
        <header
          v-if="withHeader"
          class="header"
        >
          <h4
            v-if="title"
            class="title"
          >
            {{title}}
          </h4>
          <MFlexSpacer />
          <div class="actions">
            <slot name="actions" />
            <a
              v-if="dismissable"
              href="#dismiss-modal"
              class="close"
              @click.prevent="dismiss"
            >
              <MIcon icon="xmark" />
            </a>
          </div>
        </header>
        <div
          class="content"
          :style="windowStyles"
        >
          <slot />
        </div>

        <footer
          v-if="$slots.footer"
          class="modal-footer"
        >
          <slot name="footer" />
        </footer>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss">
@import '../../assets/styles/breakpoints.scss';

.m-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3000000000; // higher than the freshdesk widget
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .no-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: .5em;
    align-content: center;
    align-items: center;
    z-index: 2;

    .title {
      margin: 0;
      flex: 1;
    }

    .actions {
      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 2em;
        height: 2em;
        color: var(--palette-neutral-90);
        background: white;
        border-radius: 50%;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.03), 0 2px 10px 0 rgba(0, 0, 0, 0.10);
        margin-left: 1rem;

        &:hover {
          background: var(--palette-neutral-10);
        }
      }
    }
  }

  .window {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 20em;
    background-color: white;
    animation: appear 500ms ease-out forwards;

    .header {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1em;
      margin-bottom: .5em;
      border-bottom: 1px solid var(--palette-neutral-20);
      z-index: 2;

      .title {
        margin: 0;
      }

      .actions svg {
        color: var(--color-typography);
      }
    }

    .content {
      position: relative;
      height: 100%;
      overflow: auto;
      padding: 1em;
    }
  }

  &.with-backdrop {
    background-color: rgba(#334E68, 0.7);
    animation: fade-backdrop 250ms ease-out forwards;
    padding: 1em;

    @include breakpoint('small') {
      padding: 3em;
    }

    .window {
      border-radius: 1rem;
      overflow: hidden;
    }
  }

  .modal-footer {
    .m-toolbar {
      padding: 1em;
    }
  }

  &.preset-image {
    padding: 3vw;

    .window {
      background: none;
      display: block;
    }

    .content {
      padding: 0;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      background-color: white;
    }
  }

  &.preset-embed {
    padding: 3vw;

    .window {
      height: auto;
    }

    .content {
      padding: 0;
    }

    iframe {
      display: block;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &.preset-dialog {
    .window {
      width: auto;
      max-width: 35em;
      border-radius: 1em;
      box-shadow: 0 1em 2em rgba(#000, 0.1);
      overflow: hidden;
      min-width: unset;

      @include breakpoint('small') {
        min-width: 25em;
      }
    }
  }

  &.preset-form {
    .window {
      width: auto;
      border-radius: 1em;
      box-shadow: 0 1em 2em rgba(#000, 0.1);
      overflow: hidden;

      @include breakpoint('medium') {
        max-width: 80vw;
      }

      @include breakpoint('large') {
        max-width: 60vw;
      }
    }

    .header {
      .title {
        text-align: center;
      }
    }
  }

  &.preset-video {
    .window {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background: none;

      .content {
        display: flex;
        justify-content: center;
        aspect-ratio: 16/9;
        width: 100%;
        height: auto;
        padding: 0;
      }
    }
  }

  &.preset-pacsbin {
    .window {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: none;

      .content {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 0;

        iframe {
          width: 100%;
          height: 100%;
          border: 0;
        }
      }
    }
  }
}

@keyframes fade-backdrop {
  from {
    background-color: transparent;
  }
}

@keyframes appear {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
}
</style>