<script lang="ts" setup>
import type { ConcreteComponent, ComputedOptions, MethodOptions, FunctionalComponent } from 'vue';

const props = withDefaults(defineProps<{
    element?: 'a' | 'button' | 'div' | 'span' | ConcreteComponent<{}, any, any, ComputedOptions, MethodOptions> | FunctionalComponent<{}, any, any> | string;
    href?: string;
    value?: string;
    iconLeft?: string;
    iconRight?: string;
    disabled?: boolean;
    loading?: boolean | string;
    triggerIn?: number | false;
    secondary?: boolean;
  }>(), {
  element: 'button',
  href: undefined,
  value: undefined,
  iconLeft: undefined,
  iconRight: undefined,
  loading: false,
  triggerIn: false,
  secondary: true, // true by default because it's the most common use case, fix on refactor
});

const { href, value, iconLeft, iconRight, disabled, loading, triggerIn } = toRefs(props);

const buttonEl = ref<HTMLElement | null>(null);
const emit = defineEmits<{
  (e: 'trigger'): void;
}>();

const setupTrigger = () => {
  if (typeof(triggerIn.value) === 'number' && buttonEl.value) {
    setTimeout(() => {
      emit('trigger');
    }, triggerIn.value);
  }
};

watch(triggerIn, () => {
  setupTrigger();
});

onMounted(() => {
  setupTrigger();
});
</script>

<template>
  <Component
    :is="element"
    v-bind="{href, disabled}"
    ref="buttonEl"
    class="button-toolbar"
    :class="{
      'disabled': disabled,
      'primary': !secondary,
      'secondary': secondary
    }"
  >
    <template v-if="loading">
      <MIcon
        name="spinner"
        class="icon-left"
        :icon="iconLeft || ''"
        spin
      />
      <span class="label">
        {{typeof loading === 'string' ? loading : 'Loading'}}
      </span>
    </template>
    <template v-else>
      <MIcon
        v-if="iconLeft"
        :name="iconLeft"
        :icon="iconLeft"
        class="icon-left"
      />

      <span class="label">
        <template v-if="value">
          {{value}}
        </template>

        <slot v-else />
      </span>

      <MIcon
        v-if="iconRight"
        :name="iconRight"
        :icon="iconRight"
        class="icon-right"
      />
    </template>
    <div
      v-if="triggerIn"
      class="progress"
      :style="{
        animation: `progress ${triggerIn}ms linear forwards`
      }"
    />
  </Component>
</template>

<style lang="scss">
  @import '../../assets/styles/font-styles.scss';

  .button-toolbar {
    @include f-16-bold;

    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em 1.25em;
    border-radius: 0.5rem;
    transition: all 0.2s ease-out;
    line-height: 1.4;
    color: var(--palette-neutral-80);
    border: 0;
    background-color: transparent;
    user-select: none;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      background-color: var(--palette-neutral-20);
    }

    &:active {
      background-color: var(--palette-neutral-30);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.primary {
      background-color: var(--palette-blue-50);
      color: white;

      &:hover,
      &:active {
        background-color: var(--palette-blue-40);
      }
    }

    .icon-left {
      margin-right: 0.5rem;
    }

    .icon-right {
      margin-left: 0.5rem;
    }

    .m-icon,
    .label {
      position: relative;
      z-index: 2;
    }

    .progress {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: var(--palette-blue-30);
    }
  }

  @keyframes progress {
    0% {
      width: 0;
    }

    100% {
      width: 100%;
    }
  }
</style>