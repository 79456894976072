<script setup lang="ts">

const { logout } = useAuthentication();

const dismiss = () => {
  window.location.href = useCreateLink().createAppLink('/signup/confirm-email');
};

// FIXME: we need to add the verify now step ... 🔥

</script>

<template>
  <MModal
    class="verify-account-modal"
    title="Welcome to Medmastery!"
    :preset="'dialog'"
    :with-header="true"
    :dismissable="false"
  >
    <div class="message">
      <p>
        <b><MIcon icon="shoe-prints" /> A small step towards great learning!</b>
      </p>
      <p>
        Before you dive into a world of knowledge, we need you to verify your email address. It's a quick step that ensures your account security and unlocks all the educational features we offer.
      </p>
      <p>
        <MIcon icon="graduation-cap" /> Ready to start your learning journey? Please check your inbox for our verification email and complete this final step.
      </p>
      <p>
        If you need assistance or haven't received our email, our support team (<a href="mailto:support@medmastery.com">support@medmastery.com</a>) is here to help.
      </p>
      <p>
        Thank you for choosing us for your learning adventure. Let’s embark on this journey of discovery and growth together!
      </p>
    </div>
    <template #footer>
      <MToolbar
        position="bottom"
        class="message-actions"
      >
        <MButtonToolbar
          :small="true"
          @click="logout"
        >
          Logout
        </MButtonToolbar>
        <MButtonToolbar
          :secondary="false"
          @click="dismiss"
        >
          Verify now
        </MButtonToolbar>
      </MToolbar>
    </template>
  </MModal>
</template>

<style scoped lang="scss">
.verify-account-modal {
  .message {
    padding: 1em;

    .m-icon {
      vertical-align: text-bottom;
    }
  }

  .message-actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }
}
</style>