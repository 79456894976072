<script setup lang="ts">
import { useOnline } from '@vueuse/core';

const { user, isLoading }  = useUserStore();
const isOnline = useOnline();
const { dispatchMessage } = useSystemMessages();

const showVerifyYourAccountModal = ref(false);

watch(isOnline, (newValue: boolean) => {
  if (newValue) {
    dispatchMessage('Back online.', 'success', 'Your browser is back online.', 10000);
    return;
  }

  dispatchMessage('You are offline.', 'warn', 'Your browser went offline, please check your internet connection.', 15000);
});

watch(isLoading, newValue => {
  if (newValue === true) {
    return;
  }

  if (user && user.value?.isVerified === false) {
    showVerifyYourAccountModal.value = true;
  }
});
</script>

<template>
  <SystemMessages />

  <slot />

  <UserVerifyYourAccountModal v-if="showVerifyYourAccountModal" />
</template>
