<script setup lang="ts">
const props = withDefaults(defineProps<{
    sticky?: boolean;
    position?: 'top' | 'bottom';
  }>(), {
  sticky: false,
  position: 'top',
});

const { sticky, position } = toRefs(props);
</script>

<template>
  <div
    :class="{
      'm-toolbar': true,
      'm-toolbar--sticky': sticky,
      'm-toolbar--top': position === 'top',
      'm-toolbar--bottom': position === 'bottom'
    }"
  >
    <slot />
  </div>
</template>

<style lang="scss">
  .m-toolbar {
    background: white;

    &--sticky {
      position: sticky;
      z-index: 900;
    }

    &--top {
      top: 0;
      border-bottom: 1px solid var(--palette-neutral-20);
    }

    &--bottom {
      bottom: 0;
      border-top: 1px solid var(--palette-neutral-20);
    }
  }
</style>
